import React from 'react'
import { Link } from 'react-router-dom'


import img1 from '../assets/testimonials/img1.jpg';
import img2 from '../assets/testimonials/img2.jpg';
import img3 from '../assets/testimonials/img3.jpg';

const PCTA = () => {
  return (
    <div className="container m-auto px-6 space-y-8 text-gray-500 lg:px-20">
      <div className="p-6 mx-auto max-w-sm sm:max-w-xl md:max-w-full lg:max-w-screen-xl">
        <div className="justify-center text-center gap-6 lg:text-left lg:flex lg:items-center lg:gap-16">
          <div className="order-last mb-6 space-y-6 lg:mb-0 lg:w-6/12">
            <h1 className="text-3xl text-gray-800 font-semibold tracking-tight sm:text-4xl">Take Flight with <span className="text-primary">Top Talent</span></h1>
            <p className=" text-base text-gray-700 md:text-lg mb-12">Ready to elevate your team? Partner with us to access top aviation talent and ensure smooth, compliant operations.</p>

            <div className="flex flex-wrap justify-center gap-4 lg:justify-start">
              <Link to='/connect' type="button" className="w-max py-3 px-6 text-center rounded-xl bg-primary shadow-xl hover:bg-primary-hover active:bg-primary focus:bg-primary-hover duration-300 transition-colors">
                <span className="block text-white font-semibold">
                  Connect
                </span>
              </Link>
            </div>
          </div>
          <div className="mt-28 lg:mt-0 grid grid-cols-5 grid-rows-4 gap-4 lg:w-6/12">
            <div className="col-span-2 row-span-4">
              <img src={img2} className="rounded-full shadow-2xl" width="640" height="960" alt="Student with Books" />
            </div>
            <div className="col-span-2 row-span-2">
              <img src={img1} className="w-full h-full object-cover object-top shadow-2xl rounded-2xl sm:rounded-3xl" width="640" height="640" alt="Student Holding Degree" />
            </div>
            <div className="col-span-3 row-span-3">
              <img src={img3} className="w-full h-full object-cover object-top shadow-2xl rounded-2xl sm:rounded-3xl" width="640" height="427" alt="Student Sitting with Bag" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PCTA