import React from 'react'

const AboutInfo = () => {
    return (
        <div className="flex flex-col mx-auto space-y-12 mt-10">
            <div className="relative">
                {/* mt-2 text-3xl font-bold tracking-tight text-primary sm:text-4xl */}
                <h2 className="w-full title text-center">About Us</h2>
                <p className="w-full py-8 mx-auto -mt-2 subdesc text-center sm:max-w-3xl">Empowering the aviation industry with tailored staffing solutions, skilled professionals, and a seamless hiring experience. We provide a trusted and efficient pathway to connect top aviation talent with industry leaders.</p>
            </div>
            <div className="flex flex-col mb-10 animated fadeIn sm:flex-row">
                <div className="flex items-center mb-16 sm:w-1/2 md:w-5/12 sm:order-last">
                    <img className="rounded-lg shadow-xl" src='https://dl.dropboxusercontent.com/scl/fi/2xgwdibh2g91woux2fxe5/commitments.jpg?rlkey=9t77lclo7cvwspq2g41hj8k02&dl=0' alt="Commitments" />
                </div>
                <div className="flex flex-col justify-center mt-5 mb-8 md:mt-0 sm:w-1/2 md:w-7/12 sm:pr-16">
                    {/* <p className="mb-2 text-sm font-semibold leading-none text-left text-indigo-600 uppercase">Drag-n-drop design</p> */}
                    <h3 className="mt-2 text-xl font-semibold sm:text-left sm:text-2xl text-gray-800">Our Commitments</h3>
                    <p className="mt-5 text">We are dedicated to providing top-quality staffing solutions for the aviation sector. Our commitment lies in connecting talented aviation professionals with employers to fulfill industry demands and drive mutual success. We prioritize quality, reliability, and the long-term growth of our partners and candidates.</p>
                </div>
            </div>
            <div className="flex flex-col mb-10 animated fadeIn sm:flex-row">
                <div className="flex items-center mb-16 sm:w-1/2 md:w-5/12">
                    <img className="rounded-lg shadow-xl" src='https://dl.dropboxusercontent.com/scl/fi/gjye4ck4gsu1h6ykd5yj9/objective.jpg?rlkey=4efckjkjbdx4xrrtjkaudtvb5&dl=0' alt="Objective" />
                </div>
                <div className="flex flex-col justify-center mt-5 mb-8 md:mt-0 sm:w-1/2 md:w-7/12 sm:pl-16">
                    {/* <p className="mb-2 text-sm font-semibold leading-none text-left text-indigo-600 uppercase">know your data</p> */}
                    <h3 className="mt-2 text-xl font-semibold sm:text-left sm:text-2xl text-gray-800">Our Objective</h3>
                    <p className="mt-5 text">Our objective at AirSync Staffing is to support the aviation industry by identifying and connecting skilled professionals with organizations in need. “Your Success is Our Mission” guides our approach, ensuring that each placement brings value and aligns with client needs while fostering candidate career growth.</p>
                </div>
            </div>
            <div className="flex flex-col mb-10 animated fadeIn sm:flex-row">
                <div className="flex items-center mb-16 sm:w-1/2 md:w-5/12 sm:order-last">
                    <img className="rounded-lg shadow-xl" src='https://dl.dropboxusercontent.com/scl/fi/o7utsbd4uuljdpwq5juu1/benefits.jpg?rlkey=ykxlj2hbqxk7pv711ok1lhzzx&dl=0' alt="Benefits" />
                </div>
                <div className="flex flex-col justify-center mt-5 mb-8 md:mt-0 sm:w-1/2 md:w-7/12 sm:pr-16">
                    {/* <p className="mb-2 text-sm font-semibold leading-none text-left text-indigo-600 uppercase">Easy to customize</p> */}
                    <h3 className="mt-2 text-xl font-semibold sm:text-left sm:text-2xl text-gray-800">Working for Your Advantage</h3>
                    <p className="mt-5 text">At AirSync Staffing, we’re dedicated to benefiting both clients and candidates. Whether you’re seeking skilled professionals for immediate roles or candidates aiming for career growth in aviation, our services are designed to create meaningful connections. We’re here to provide reliable staffing solutions that enhance industry standards and drive success for all involved.</p>
                </div>
            </div>
        </div>

    )
}

export default AboutInfo