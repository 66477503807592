import React from 'react'

//Icons
import { SiSemanticscholar } from 'react-icons/si';
import { BiUserVoice } from 'react-icons/bi';
import { RiHandCoinLine } from 'react-icons/ri';
import { LiaUniversitySolid, LiaHandsHelpingSolid } from 'react-icons/lia';
import { MdOutlineAssignment } from 'react-icons/md';
import { TiTickOutline } from 'react-icons/ti';
import { TbProgressCheck } from 'react-icons/tb';
import { AiOutlineSolution } from 'react-icons/ai';


const WhyUs = () => {
    return (
        <div>
            <div className="flex flex-wrap -mx-4">
                <div className="w-full px-4">
                    <div className="mx-auto mb-12 max-w-[510px] text-center lg:mb-20">
                        {/* <span className="block mb-2 text-lg font-semibold text-primary">
                                Our Services
                            </span> */}
                        <h2 className="mb-4 title">
                            Why Us?
                        </h2>
                        <p className="mb-10 subdesc">
                        9 Key Reasons to Choose AirSync Staffing
                        </p>
                    </div>
                </div>
            </div>
            <div className="grid row-gap-8 sm:row-gap-0 sm:grid-cols-2 lg:grid-cols-3">
                <div className="p-8 border-b sm:border-r">
                    <div className="max-w-md text-center">
                        <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-16 sm:h-16 shadow-md">
                            <TiTickOutline className='w-8 h-8 text-gray-700' />
                        </div>
                        <h6 className="mb-2 font-semibold leading-5 text-gray-800">Industry Expertise</h6>
                        <p className="mb-3 text-sm text-gray-700 leading-5">
                        Our team comprises experienced and qualified aviation staffing professionals with in-depth knowledge of the industry’s demands and hiring processes.
                        </p>
                    </div>
                </div>
                <div className="p-8 border-b lg:border-r">
                    <div className="max-w-md text-center">
                        <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-16 sm:h-16 shadow-md">
                            <AiOutlineSolution className='w-10 h-10 text-gray-700' />
                        </div>
                        <h6 className="mb-2 font-semibold leading-5 text-gray-800">Tailored Solutions</h6>
                        <p className="mb-3 text-sm text-gray-700 leading-5">
                        We understand that each client’s needs are unique, and we provide customized staffing solutions based on individual company requirements and roles.
                        </p>
                    </div>
                </div>
                <div className="p-8 border-b sm:border-r lg:border-r-0">
                    <div className="max-w-md text-center">
                        <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-16 sm:h-16 shadow-md">
                            <TbProgressCheck className='w-10 h-10 text-gray-700' />
                        </div>
                        <h6 className="mb-2 font-semibold leading-5 text-gray-800">Transparency</h6>
                        <p className="mb-3 text-sm text-gray-700 leading-5">
                        We maintain complete transparency throughout the recruitment process, keeping clients and candidates updated at every step.
                        </p>
                    </div>
                </div>
                <div className="p-8 border-b lg:border-r">
                    <div className="max-w-md text-center">
                        <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-16 sm:h-16 shadow-md">
                            <RiHandCoinLine className='w-10 h-10 text-gray-700' />
                        </div>
                        <h6 className="mb-2 font-semibold leading-5">Affordable Services</h6>
                        <p className="mb-3 text-sm text-gray-700 leading-5">
                        We believe every organization deserves top-tier talent, and we offer competitive rates to make staffing affordable.
                        </p>
                    </div>
                </div>
                <div className="p-8 border-b sm:border-r">
                    <div className="max-w-md text-center">
                        <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-16 sm:h-16 shadow-md">
                            <LiaUniversitySolid className='w-10 h-10 text-gray-700' />
                        </div>
                        <h6 className="mb-2 font-semibold leading-5">Talent Selection</h6>
                        <p className="mb-3 text-sm text-gray-700 leading-5">
                        We help identify the right candidates for each aviation role, matching skills and experience to ensure a seamless fit with company culture.
                        </p>
                    </div>
                </div>

                <div className="p-8 border-b">
                    <div className="max-w-md text-center">
                        <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-16 sm:h-16 shadow-md">
                            <LiaHandsHelpingSolid className='w-10 h-10 text-gray-700' />
                        </div>
                        <h6 className="mb-2 font-semibold leading-5">Application Guidance</h6>
                        <p className="mb-3 text-sm text-gray-700 leading-5">
                        We assist candidates with the application process, from filling out required forms to preparing them for interviews with our clients.
                        </p>
                    </div>

                </div>

                <div className="p-8 border-b sm:border-r lg:border-b-0">
                    <div className="max-w-md text-center">
                        <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-16 sm:h-16 shadow-md">
                            <MdOutlineAssignment className='w-10 h-10 text-gray-700' />
                        </div>
                        <h6 className="mb-2 font-semibold leading-5">Compliance Assistance</h6>
                        <p className="mb-3 text-sm text-gray-700 leading-5">
                        We guide candidates and employers through the necessary regulatory compliance, ensuring all certifications and requirements are met.
                        </p>
                    </div>
                </div>

                <div className="p-8 border-b lg:border-b-0 lg:border-r">
                    <div className="max-w-md text-center">
                        <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-16 sm:h-16 shadow-md">
                            <BiUserVoice className='w-10 h-10 text-gray-700' />
                        </div>
                        <h6 className="mb-2 font-semibold leading-5">Orientation & Onboarding</h6>
                        <p className="mb-3 text-sm text-gray-700 leading-5">
                        We provide comprehensive briefings and onboarding support to ensure candidates are well-prepared and confident in their new roles.
                        </p>
                    </div>
                </div>

                <div className="p-8 sm:border-r lg:border-r-0">
                    <div className="max-w-md text-center">
                        <div className="flex items-center justify-center w-16 h-16 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-16 sm:h-16 shadow-md">
                            <SiSemanticscholar className='w-10 h-10 text-gray-700' />
                        </div>
                        <h6 className="mb-2 font-semibold leading-5">Ongoing Support</h6>
                        <p className="mb-3 text-sm text-gray-700 leading-5">
                        We offer continued support for both clients and candidates, providing a smooth transition and resolving any post-placement queries.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WhyUs