import React from 'react'
// import { HashLink } from 'react-router-hash-link'
//icons
// import { FaArrowRight } from 'react-icons/fa'
import { BookOpenIcon, BanknotesIcon, StarIcon, BriefcaseIcon, AcademicCapIcon, CheckCircleIcon } from '@heroicons/react/24/outline';


const features = [
    {
        name: 'Candidate Sourcing & Screening',
        description:
            'We specialize in sourcing skilled aviation professionals, from pilots to maintenance staff, through rigorous screening processes. Our team evaluates qualifications, certifications, and experience to ensure candidates meet industry standards before presenting them to clients.',
        icon: BriefcaseIcon,
    },
    {
        name: 'Onboarding & Orientation',
        description:
            'AirSync Staffing facilitates smooth onboarding by providing candidates with comprehensive orientation programs tailored to each client’s operational protocols, safety standards, and organizational culture. This ensures a seamless transition into the workplace.',
        icon: AcademicCapIcon,
    },
    {
        name: 'Regulatory Compliance Support',
        description:
            'Our team assists with compliance documentation, ensuring that all candidates have the necessary certifications, background checks, and meet regulatory standards, providing clients with peace of mind and minimizing onboarding delays.',
        icon: CheckCircleIcon,
    },
    {
        name: 'Temporary & Contract Staffing',
        description:
            'We offer flexible staffing solutions, including temporary and contract placements for short-term and project-based needs. This service allows clients to scale their workforce based on demand without long-term commitments.',
        icon: BanknotesIcon,
    },
    {
        name: 'Talent Pipeline Management',
        description:
            'AirSync Staffing maintains a proactive talent pipeline to fill critical roles quickly. We keep an ongoing roster of pre-screened, qualified candidates for high-demand positions, ensuring fast, reliable staffing solutions when clients need them.',
        icon: StarIcon,
    },
    {
        name: 'Emergency & Rapid Response Staffing',
        description:
            'For urgent staffing needs, AirSync provides rapid response recruitment services to minimize operational downtime. Our team quickly sources and deploys skilled professionals to address unforeseen demands, ensuring continued operations.',
        icon: BookOpenIcon,
    },
];


const WhyPrograms = () => {
    return (
        <div className="">
            {/* <div className="bg-white py-24 sm:py-32">
            <div className="flex flex-col items-center mx-auto max-w-7xl px-6 lg:px-8"> */}
            <div className="flex flex-col items-center">
                <div className="mx-auto max-w-2xl text-center">
                    {/* <h2 className="text-base font-semibold leading-7 text-indigo-600">Deploy faster</h2> */}
                    <p className="mt-2 title">
                        Services We Offer
                    </p>
                    <p className="mt-6 subdesc">
                    AirSync Staffing offers specialized services to support aviation businesses with reliable, compliant, and efficient staffing solutions.                    </p>
                </div>
                <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
                    <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
                        {features.map((feature) => (
                            <div key={feature.name} className="relative pl-16">
                                <dt className="font-semibold leading-7 text-gray-800">
                                    <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-primary shadow-md">
                                        <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                                    </div>
                                    {feature.name}
                                </dt>
                                <dd className="mt-3 text-sm font-medium lg:text-base leading-5 text-gray-700">{feature.description}</dd>
                            </div>
                        ))}
                    </dl>
                </div>
                {/* <HashLink to='/about#details' smooth className="flex items-center gap-1.5 mt-20 font-semibold leading-7 text-primary hover:text-gray-500 cursor-pointer lg:text-center duration-300 transition-colors">
                    Learn More <FaArrowRight />
                </HashLink> */}
            </div>
        </div>

    )
}

export default WhyPrograms