import React from 'react';

const Benefits = () => {
    return (
        <div>
            <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
                <h2 className="max-w-lg mb-6 title sm:mx-auto text-center">
                    <span className="relative inline-block">
                        <svg
                            viewBox="0 0 52 24"
                            fill="currentColor"
                            className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
                        >
                            <defs>
                                <pattern
                                    id="9a29985a-fc16-419b-ae53-1670f5ca4491"
                                    x="0"
                                    y="0"
                                    width=".135"
                                    height=".30"
                                >
                                    <circle cx="1" cy="1" r=".7" />
                                </pattern>
                            </defs>
                            <rect
                                fill="url(#9a29985a-fc16-419b-ae53-1670f5ca4491)"
                                width="52"
                                height="24"
                            />
                        </svg>
                        <span className="relative">Tailored Staffing Solutions</span>
                    </span>{' '}
                    for Every Aviation Role
                </h2>
                <p className="subdesc text-center">
                    Connecting top aviation professionals with organizations that value expertise, reliability, and growth.
                </p>
            </div>
            <img
                className="object-cover w-full h-56 rounded-3xl shadow-2xl md:hidden"
                src="https://dl.dropboxusercontent.com/scl/fi/7n05a7gjr849k0os5nln7/common.jpg?rlkey=5uizsfx9tsuhwcy9u55ybrvrb&dl=0"
                alt="Aviation Professionals"
            />
            <div className="hidden md:grid max-w-screen-lg gap-8 row-gap-5 mb-8 md:grid-cols-3 mx-auto">
                <img
                    className="object-cover w-full h-56 shadow-2xl rounded-3xl"
                    src="https://dl.dropboxusercontent.com/scl/fi/ktv016xouzzhif78mu9f7/highschool.jpg?rlkey=yul0ffnnm8tjnc3npshphyoe8&dl=0"
                    alt="Experienced Pilots"
                />
                <img
                    className="object-cover w-full h-56 shadow-2xl rounded-3xl"
                    src="https://dl.dropboxusercontent.com/scl/fi/9los9266k6btpmraze6co/ug.jpg?rlkey=av09wkbwvf7lqoipfbnssp973&dl=0"
                    alt="Aviation Technicians"
                />
                <img
                    className="object-cover w-full h-56 shadow-2xl rounded-3xl"
                    src="https://dl.dropboxusercontent.com/scl/fi/w6umea0ooxx1x7fstb42q/professional.jpg?rlkey=dsq0rux43ogvu7uph085wau8n&dl=0"
                    alt="Ground Staff"
                />
            </div>
            <div className='mt-16 text space-y-8'>
                <p>
                    At <span className='text-primary font-semibold hover:text-gray-700 cursor-pointer duration-300 transition-colors'>AirSync Staffing</span>, we understand the unique demands of the aviation industry. Whether you’re an experienced pilot, a skilled technician, or dedicated ground staff, our mission is to match you with opportunities that align with your expertise and career goals.
                </p>

                <p>
                    Our team stays current with aviation industry standards and trends, ensuring we can offer valuable insights and tailored support. We partner closely with leading aviation organizations to ensure that each placement is mutually beneficial, contributing to both individual growth and organizational success.
                </p>

                <p>
                    Join forces with AirSync Staffing to connect with aviation employers who prioritize skill, safety, and excellence. Whether you’re looking to advance your career or bring talent to your team, we’re here to support your journey in aviation.
                </p>
            </div>
        </div>
    );
};

export default Benefits;
