import React from 'react'

const ProgramBenefits = () => {
    return (
            <div className="flex flex-col mx-auto space-y-12" id='details'>
        <div className="relative">
            <h2 className="w-full title text-center">Discover Benefits</h2>
            <p className="w-full py-8 mx-auto -mt-2 subdesc text-center sm:max-w-3xl">Explore the advantages of partnering with AirSync Staffing and how our services can elevate your hiring experience in the aviation industry.</p>
        </div>
        <div className="animated fadeIn">
            <div className="mt-5">
                <h3 className="mt-8 text-xl font-semibold sm:text-left sm:text-2xl text-gray-800">Cost-Effective Staffing Solutions</h3>
                <p className="mt-5 mb-10 pb-10 border-b-2 text">AirSync Staffing is dedicated to providing aviation companies with affordable and flexible staffing options. We understand the budget constraints many organizations face and aim to deliver top-quality talent without overextending your resources. Our commitment is to offer cost-effective solutions, ensuring access to skilled aviation professionals while keeping hiring costs manageable.</p>

                <h3 className="mt-8 text-xl font-semibold sm:text-left sm:text-2xl text-gray-800">Comprehensive Talent Network</h3>
                <p className="mt-5 mb-10 pb-10 border-b-2 text">AirSync Staffing maintains an extensive network of qualified aviation professionals, ensuring we can meet the unique demands of the industry. From licensed mechanics to experienced pilots, our pool of candidates is equipped with the necessary skills to excel. We provide resources like skill assessment, training programs, and industry insights to keep our talent sharp and ready for immediate integration into your team.</p>

                <h3 className="mt-8 text-xl font-semibold sm:text-left sm:text-2xl text-gray-800">Opportunities for Career Growth</h3>
                <p className="mt-5 mb-10 pb-10 border-b-2 text">At AirSync Staffing, we open doors to career advancement opportunities for our candidates. Our strong industry connections enable us to provide job seekers with access to reputable employers and professional development workshops. We organize networking events, training sessions, and mentorship programs to help candidates connect with industry leaders and explore various career paths within aviation.</p>

                <h3 className="mt-8 text-xl font-semibold sm:text-left sm:text-2xl text-gray-800">Streamlined Hiring Process</h3>
                <p className="mt-5 mb-10 pb-10 border-b-2 text">AirSync Staffing simplifies the hiring process by leveraging our expertise in aviation recruitment. We handle every aspect, from candidate screening to interview preparation, so clients can focus on core operations. Our team provides personalized support to ensure a smooth onboarding experience, helping organizations secure top talent with minimal hassle.</p>

                <h3 className="mt-8 text-xl font-semibold sm:text-left sm:text-2xl text-gray-800">Reliable Outcomes</h3>
                <p className="mt-5 mb-10 pb-10 border-b-2 text">We are committed to delivering reliable outcomes for both our clients and candidates. AirSync Staffing works tirelessly to match the right talent with the right positions, ensuring long-term satisfaction for both parties. Our proven track record in the aviation staffing industry is a testament to our dedication to quality and successful placements.</p>

                <h3 className="mt-8 text-xl font-semibold sm:text-left sm:text-2xl text-gray-800">Maximized Value</h3>
                <p className="mt-5 pb-10 text">With AirSync Staffing, clients and candidates alike benefit from our extensive resources and industry knowledge. Our services go beyond traditional staffing; we offer guidance, professional development, and networking opportunities that add lasting value to every partnership. Trust AirSync Staffing to bring you the highest quality support and expertise in aviation staffing.</p>
            </div>
        </div>
    </div>

    )
}

export default ProgramBenefits